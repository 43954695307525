// Colors
// ------
$red: #c7201a;
$magenta: #c025af;
$magenta-dark: #8c0560;
$blue: #2e87c0;
$orange: #ea7400;
$muted: darken($medium-gray, 15%);


// General
// -------
#header-wrapper {
    background-color: $magenta-dark;
    header {
        width: 100%;
        height:100vh;
        color: $white;
        min-height: rem-calc(500);
        background-color: $black;
        background-image: url(../../assets/img/header-bg.jpg);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        .row {
            height: 100%;
        }
        a {
            color: $white;
            text-transform: uppercase;
            font-weight: bold;
            &:hover {
                color: darken($white, 40%);
            }
            &:focus {
                color: $white;
            }
        }
        h1 {
            //text-transform: uppercase;
            font-weight: bold;
            //font-family: 'Cinzel Decorative', cursive;
            //font-family: 'Quantico', sans-serif;
            font-family: 'Open Sans', sans-serif;
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
            span {
                border-bottom: rem-calc(3) solid $white;
            }
        }
        .lead {
            @include header-size('h1', .42);
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
        }
        .menu {
            position: fixed;
        }
        #da {
            position: absolute;
            bottom: rem-calc(50);
            width: 100px;
            left:50%;
            margin-left:-50px;
            @include breakpoint(small) {
                @include breakpoint(landscape) {
                    display: none;
                }
            }
            @include breakpoint(medium) {
                display: inline-block;
            }
        }
    }
}
hr {
 margin: rem-calc(50) 0 rem-calc(40) 0;
}
#about {
    background: $black  url(../../assets/img/body-bg.jpg) no-repeat fixed center center / cover ;
    color: $white;
    .medium-order-1 {
        padding: rem-calc(25) rem-calc(25) rem-calc(50);
    }
    .medium-order-2 {
        padding: rem-calc(25);
    }
    .medium-order-1,
    .medium-order-2 {
        @include breakpoint(medium) {
            padding: rem-calc(60);
        }
    }
    .medium-order-2 {
        background: rgba($black, 0.7);
    }
    .row.small-collapse {
        .columns {
            padding: rem-calc(20) 0;
        }
    }
}

#da-cover-wrap {
    background: rgba($black, .2%);
    padding: rem-calc(20);
    #da-cover {
        max-width: rem-calc(250);
        margin: 0 auto;
        cursor: pointer;
        figure {
            figcaption {
                font-size: $global-font-size * .8;
            }
        }
    }
}

.reveal.active {
    background: $black;
    border: 1px solid black;
}
.columns {
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(40);
}
#main-nav {
    position: fixed;
    float: left;
    top: 0;
    background: lighten($black, 12%);
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    z-index: 1000;
    a {
        color: $white;
        font-weight: bold;
    }
    .active {
            color: red;
    }
}
.inline {
    li {
        display: inline;
    }
}

// Padding
// -------
.p0 {
    padding: 0 !important;
}
.pb0 {
    padding-bottom: 0 !important;
}
.pt30 {
    padding-top: rem-calc(30) !important;
}
// Backgrounds
// -----------
.bg-primary {
    background: $primary-color;
}
.bg-secondary {
    background: $secondary-color;
}
.bg-black {
    background: $black;
    color: $white;
}
.bg-white {
    background: $white;
}
.bg-dark-gray {
    background: $dark-gray;
    color: $white;
}
.bg-light-gray {
    background: $light-gray !important;
}


// Cube Portfolio - Work Gallery
// -----------------------------
#js-filters-juicy-projects {
    padding-top: rem-calc(30);
}
#js-loadMore-juicy-projects {
    padding-bottom: rem-calc(30);
}
.cbp-l-project-details-list {
    margin-bottom: rem-calc(20);
}
.cbp-l-grid-projects-title,
.cbp-l-grid-projects-desc,
.cbp-l-loadMore-button .cbp-l-loadMore-link,
.cbp-l-loadMore-button .cbp-l-loadMore-button-link,
.cbp-l-filters-button .cbp-filter-item,
.cbp-l-project-desc-text,
.cbp-l-project-details-list > li,
.cbp-l-project-details-list > div,
.cbp-l-project-title,
.cbp-l-project-subtitle,
.cbp-l-project-desc-title span, 
.cbp-l-project-details-title span,
.cbp-l-project-related-title {
    font-family: $body-font-family;
}
.cbp-l-project-related-title {
    padding-bottom: rem-calc(30);
}
.cbp-nav-stop {
    cursor: default;
    opacity: 0 !important;
}
.cbp-popup-singlePage .cbp-popup-navigation-wrap {
    background: lighten($dark-gray, 8%); 
}
.cbp-slider-wrap.no-scale {
    img {
        width: auto;
        margin: 0 auto;
    }
}
.cbp-slider.dark {
    .cbp-nav-next::after,
    .cbp-nav-prev::after {
        background-image: url("../img/cbp-sprite-dark.png");
    }
}
.cbp-l-project-container {
    $gallery-font-size: $global-font-size * .9;
    margin-bottom: rem-calc(60);
    .cbp-l-project-desc-text {
        font-size: $gallery-font-size;
    }
    .cbp-l-project-details-list { 
        li {
            font-size: $gallery-font-size;
        }
    }
}

// Cube Portfolio - Art Gallery
// ----------------------------
.cbp-l-grid-mosaic-flat .cbp-caption-activeWrap {
    background-color: rgba(0, 0, 0, 0.95);
}
.cbp-l-grid-mosaic-flat .cbp-l-caption-title {
    font-family: $body-font-family;
    font-family: 'Open Sans', sans-serif;
    text-transform: none;
}

// Resume Timeline
// ---------------
#resume {
    .colorize {
        .columns {
            padding: 0;
        }
        .columns::before,
        .columns::after {
            /* the 2 underneath colored sections */
            content: '';
            position: fixed;
            /* trick to remove some annoying flickering on webkit browsers */
            width: 100%;
            max-width: $global-width;
            left: 50%;
            right: auto;
            transform: translateX(-50%);
            height: 50%;
            z-index: -1;
        }
         
        .columns::before {
            top: 0;
            background-color: $blue;
            transition: all 0.8s;
        }
        .columns .focus .date-range {
            color: $blue;
        }

        .columns::after {
            top: 50%;
            background-color: $medium-gray;
        }

        .columns.new-color-1::before,
        .columns.new-color-5::before,
        .columns.new-color-9::before,
        .columns.new-color-13::before {
            background-color: $orange;
        }
        .columns.new-color-1 .focus .date-range,
        .columns.new-color-5 .focus .date-range,
        .columns.new-color-9 .focus .date-range,
        .columns.new-color-13 .focus .date-range {
            color: $orange;
        } 

        .columns.new-color-2::before,
        .columns.new-color-6::before,
        .columns.new-color-10::before {
            background-color: $red;
        }
        .columns.new-color-2 .focus .date-range,
        .columns.new-color-6 .focus .date-range,
        .columns.new-color-10 .focus .date-range {
            color: $red;
        } 

        .columns.new-color-3::before,
        .columns.new-color-7::before,
        .columns.new-color-11::before {
            background-color: $magenta;
        }
        .columns.new-color-3 .focus .date-range,
        .columns.new-color-7 .focus .date-range, 
        .columns.new-color-11 .focus .date-range {
            color: $magenta;
        }

        .columns.new-color-4::before {
            background-color: $blue;
        }
        .columns.new-color-4 .focus .date-range {
            color: $blue;
        }
         
        .cd-service {
            position: relative;
            z-index: 2;
            min-height: 50px;
            margin-left: 56px;
            background-color: $white;
            padding: 0 0 rem-calc(80) rem-calc(20);
            zoom: 1; /*hAcK*/
            border-top: 1px solid $white; /*hAcK*/
            margin-top: -1px; /*hAcK*/
            margin-left: -1px solid red; /*hAcK*/
            &:first-child {
                padding-bottom: rem-calc(20);
            }
        }
        
        .cd-service::before,
        .cd-service::after {
            content: '';
            position: absolute;
            width: 56px;
            right: 100%;
            z-index: 2;
        }
         
        .cd-service::before {
            top: 0;
            height: 52px;/*hAcK was 50*/
            background-repeat: no-repeat;
        }
         
        .cd-service::after {
            top: 50px;
            bottom: 0;
            background-image: url("../../assets/img/cd-pattern-small.svg");
            background-repeat: repeat-y;
        }
         
        .cd-service.cd-service-1::before {
            background: url("../../assets/img/cd-icon-1-small.svg");
        }
         
        .cd-service.cd-service-2::before {
            background-image: url("../../assets/img/cd-icon-2-small.svg");
        }
         
        .cd-service.cd-service-3::before {
            background-image: url("../../assets/img/cd-icon-3-small.svg");
        }
         
        .cd-service.cd-service-4::before {
            background-image: url("../../assets/img/cd-icon-4-small.svg");
        }

        .cd-service.cd-service-5::before {
            background-image: url("../../assets/img/cd-icon-5-small.svg");
        }

        .cd-service.cd-service-6::before {
            background-image: url("../../assets/img/cd-icon-6-small.svg");
        }

        .cd-service.cd-service-7::before {
            background-image: url("../../assets/img/cd-icon-7-small.svg");
        }

        ul {
            border-left: 1px solid $white; /*hAcK*/
            margin-bottom: -1px; /*hAcK was 0*/
            li {
                .date-range {   
                    margin-bottom: 0;
                    display: inline-block;
                    font-weight: bold;
                    padding-top: rem-calc(12);
                    color: $muted;
                }
                h3 {
                    margin-bottom: 0;
                }
            }
        }
        .cd-service.cd-service-divider::after {
            top: -1px; /*hAcK was 0*/
        }
    }
}

// Art & Photography
// -----------------
#artwork {
    .fa-4x {
        margin-bottom: rem-calc(20);
    }
}

// Contact (Footer)
// ----------------
#contact {
    .lead {
        margin-top: rem-calc(20);
    }
    footer {
        a {
            color: $white;
            .bt {
                margin-top: rem-calc(-8);
            }
        }
        p {
            color: darken($medium-gray, 30%);
        }
    }
}
.inline-footer {
    @extend .inline;
    li {
        margin-right: rem-calc(30);
        &:last-child {
            margin-right: 0;
        }
    }
}

// Twelve-point Burst Shape
$burst-width: 400px;
$burst-width-sm: $burst-width * .8;
$burst-color: $light-gray;
.burst-wrapper {
    .burst {
        position: relative;
        width: $burst-width-sm;
        margin: -100px auto 0 auto;
        @include breakpoint(large up) {
            width: $burst-width;
            margin: -100px auto -40px auto;
        }
        svg {
            width: $burst-width-sm;
            @include breakpoint(large up) {
                width: $burst-width;
            }
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            .b {
                fill: $burst-color;
                color-rendering: auto;
                color: $burst-color;
                isolation: auto;
                mix-blend-mode: normal;
                shape-rendering: auto;
                solid-color: $burst-color;
                image-rendering: auto
            }
        }
        h2, i {
            position: relative;
            z-index: 2;
        }
        h2 {
            padding-top: 60px;
        }
    }
}
