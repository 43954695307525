$card-user-container-margin-bottom: 1rem;
$card-user-container-radius: $global-radius;
$card-user-container-background: transparent;
$card-user-container-color: $black;
$card-user-container-image-size: rem-calc(190);

.card-user-container {
  margin-bottom: $card-user-container-margin-bottom;
  background-color: $card-user-container-background;
  color: $white;
  overflow: hidden;
  padding: 0;
  border-radius: $card-user-container-radius;

  .card-user-avatar {
    padding-top: rem-calc(10);
    .user-image {
      display: block;
      margin: auto;
      border-radius: 50%;
      width: $card-user-container-image-size;
      height: $card-user-container-image-size;
      border: rem-calc(5) solid $white;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .card-user-bio {
    text-align: center;

    .location {
      display: inline-block;
      margin: 0 rem-calc(15);
      color: darken($white, 10%);
      font-size: rem-calc(14);
    }

    .location-icon,
    .location-text {
      display: inline-block;
      padding: 0 rem-calc(5);
    }
  }

  .card-user-social {
    background: transparent;
    text-align: center;
    padding-top: rem-calc(18);

    ul {
      display: inline-block;

      li {
        display: inline-block;
        margin: 0 rem-calc(10);
        cursor: pointer;

        a {
          color: $white;
          padding: 0;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  h2 {
    font-weight: bold;
    font-size: rem-calc(35);
  }
  h3 {
    font-size: rem-calc(22);
    span {
      border-bottom: dashed rem-calc(3) $white;
      padding: 0 0 rem-calc(10) 0;
      display: inline-block;
    }
  }

  .card-user-button {
    text-align: center;
    padding: 1rem;
  }
}
