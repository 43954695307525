@media print {
    $global-font-size: 13px;
    p {
        font-size: $global-font-size;
    }
    #top-nav,
    #da,
    #about,
    #portfolio,
    #artwork,
    #main-nav,
    .cd-service-divider,
    hr {
        display: none !important;
        height: 0;
        margin: 0;
        padding: 0;
    }
    #header-wrapper {
        header {
            height: auto;
            min-height: auto;
            opacity: 1 !important;
            .row {
                height: auto;
                p {
                    margin-bottom: 0 !important;
                    padding-bottom: 0 !important;
                }
                h1 {
                    margin-bottom: 0;
                    margin-top: 2rem;
                    line-height: 1.2;
                    span {
                        border-bottom: none;
                    }
                }
            }
        }
    }
    #resume {
        .lead {
            font-size: $global-font-size;
        }
    }
    #resume .colorize .cd-service::before,
    #resume .colorize .cd-service::after {
        width: 0;
    }
    #resume .colorize .cd-service {
        margin-left: 0;
    }
    #resume .colorize .cd-service {
        min-height: 50px;
        padding: 0 1rem .5rem 1rem;
    }
    #resume .colorize .cd-service:nth-of-type(odd) {
        background-color: #fff !important;
    }
    #resume .colorize .cd-service:nth-of-type(even) {
        background-color: #eee !important;
    }
    .columns {
        padding-top: 0;
        padding-bottom: .8rem;
        background: #fff !important;
    }
    .columns::before,
    .columns::after {
        background-color: transparent !important;
    }
    h1 {
        font-size: rem-calc(43);
    }
    p {
        color: #333 !important;
    }
    h2 {
        font-size: rem-calc(30);
    }
    .fa-4x {
        font-size: 2.5rem;
    }
    .text-justify {
        text-align: left;
    }
    a {
        text-decoration: none;
    }
    a[href]:after {
        content: " " attr(href);
    }
    section {
        margin-bottom: 1rem;
    }
    footer {
        .inline-footer {
            li {
                display: block;
            }
        }
    }
}
