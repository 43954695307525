// Custom Mixins
// -------------
@mixin header-size($type, $adj) {
    font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") *$adj * 1px);
    @include breakpoint(medium){
        font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * $adj * 1px);
    }
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;
    @if $orientation == 'vertical' {
        background: -webkit-linear-gradient(top, $start-color, $end-color);
        background: linear-gradient(to bottom, $start-color, $end-color);
    } @else if $orientation == 'horizontal' {
        background: -webkit-linear-gradient(left, $start-color, $end-color);
        background: linear-gradient(to right, $start-color, $end-color);
    } @else {
        background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
        background: radial-gradient(ellipse at center, $start-color, $end-color);
    }
}

